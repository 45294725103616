import React, { JSXElementConstructor, ReactElement } from 'react';
import Icon from '../common/Icon';
import Button from '../common/forms/Button';
import { ModalProps } from './modal.model';

interface ComponentProps {
  one?: boolean;
  alert?: boolean;
  scroll?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: ReactElement<any | JSXElementConstructor<unknown>>;
  options: ModalProps;
  cancelFn?: () => void;
  heading?: string;
}

const Modal = ({ one, alert, scroll, options, children }: ComponentProps): JSX.Element => {
  // si llega la propiedad one entonces solo se pondrá un boton que recibira las caractericas de confirmFn
  if (one) {
    return (
      <div className={alert ? 'alert' : 'modal'} style={scroll ? { width: '90%' } : {}}>
        {!scroll && <Icon onClick={options.confirmFn} className="close" />}
        {React.cloneElement(children)}
        {options.confirm ? (
          <div className="actionsHolder">
            <Button
              onClick={options.confirmFn}
              className="rounded bordered cta"
              iconRight
              iconClass="arrow-right"
            >
              {options.confirmText}
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
  return (
    <div className={alert ? 'alert' : 'modal'} style={scroll ? { width: '90%' } : {}}>
      {!scroll && <Icon onClick={options.cancelFn} className="close" />}
      {React.cloneElement(children)}
      {options.cancel || options.confirm ? (
        <div className="actionsHolder">
          <Button
            onClick={options.cancelFn}
            className="rounded bordered"
            iconLeft
            iconClass="close"
          >
            {options.cancelText}
          </Button>
          <Button
            onClick={options.confirmFn}
            className="rounded bordered cta"
            iconRight
            iconClass="arrow-right"
          >
            {options.confirmText}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default Modal;
