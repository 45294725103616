import * as Sentry from '@sentry/nextjs';
import { getOrder } from '../../../graphql/queries';
import { initializeApollo } from '../../../graphql/apollo';
import {
  newEbookOrder,
  newOrder,
  updateOrderPaymentAfterStripeFlowMutation,
} from '../../../graphql/mutations';
import {
  NewEbookOrderMutation,
  NewEbookOrderMutationVariables,
  NewOrderMutation,
  NewOrderMutationVariables,
  Order,
  OrderQuery,
  OrderQueryVariables,
} from '../../../graphqlTypes';
import { Response } from '../../../models/response.model';

export const getOrderService = async (
  variables: OrderQueryVariables,
  token?: string,
): Promise<Response<Order>> => {
  try {
    const { query } = initializeApollo({}, token);
    const { data } = await query<OrderQuery>({ query: getOrder, variables });

    const order = data?.order;

    return {
      ok: !!order,
      data: order,
    };
  } catch (error: any) {
    Sentry.captureException(error);
    return {
      ok: false,
      messageError: error?.message,
    };
  }
};

export const createNewEbookOrderService = async (
  variables: NewEbookOrderMutationVariables,
): Promise<Response<Order>> => {
  try {
    const { mutate } = initializeApollo();

    const { data, errors } = await mutate<NewEbookOrderMutation>({
      mutation: newEbookOrder,
      variables,
    });

    if (errors?.length) {
      return {
        ok: false,
      };
    }
    const newOrdered = data?.newEbookOrder;

    return {
      ok: !!newOrdered,
      data: newOrdered,
    };
  } catch (error: any) {
    Sentry.captureException(error);
    return {
      ok: false,
      messageError: error.message,
    };
  }
};

export const updateOrderPaymentAfterStripeFlow = async (
  variables: { orderId: string; uncompleted?: boolean; stripePaymentIntentId?: string },
  token?: string,
): Promise<Response> => {
  try {
    const { mutate } = initializeApollo({}, token);
    await mutate({ mutation: updateOrderPaymentAfterStripeFlowMutation, variables });
    return {
      ok: true,
    };
  } catch (e: any) {
    Sentry.captureException(e);
    return null;
  }
};

export const createNewOrderService = async (
  variables: NewOrderMutationVariables,
): Promise<Response<Order>> => {
  try {
    const { mutate } = initializeApollo();
    const { data, errors } = await mutate<NewOrderMutation>({ mutation: newOrder, variables });

    if (errors?.length) {
      return {
        ok: false,
      };
    }
    const newOrdered = data?.newOrder;

    return {
      ok: !!newOrdered,
      data: newOrdered,
    };
  } catch (ex: any) {
    Sentry.captureException(ex);
    return {
      ok: false,
      messageError: ex.message,
    };
  }
};
