import { useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import shallow from 'zustand/shallow';

import { getDataFromStorage, NameStorage } from '../utils/services/localStorage.service';
import userReactiveVar from './useReactiveVariableUser';
import { getTotalUnitsInBasket } from '../components/cart/utils/cart.utils';
import { Basket } from '../graphqlTypes';
import useStoreModalCart from '../reducer/modalCart/storeModalCart';
import { HandleModalCartType } from '../layouts/Header/ModalCart/modalCart.model';

interface ModalCarthHookReturn {
  items: Basket[];
  totalCart: number;
  setItems: (item: Basket[]) => void;
  openModal: HandleModalCartType;
  setOpenModal: (openModal: HandleModalCartType) => void;
}

const useModalCart = (): ModalCarthHookReturn => {
  const userRV = useReactiveVar(userReactiveVar);
  const [items, setItems, totalCart, setTotalItems, openModal, setOpenModal] = useStoreModalCart(
    (store) => [
      store.items,
      store.setItems,
      store.totalCart,
      store.setTotalItems,
      store.openModal,
      store.setOpenModal,
    ],
    shallow,
  );

  useEffect(() => {
    return (): void => {
      setOpenModal(HandleModalCartType.Initial);
    };
  }, []);

  useEffect(() => {
    if (!items) return;

    const total = getTotalUnitsInBasket(items);
    setTotalItems(total);
  }, [items]);

  useEffect(() => {
    if (userRV) return;
    const basketLocal = getDataFromStorage(NameStorage.basket);
    if (basketLocal) return;

    setTotalItems(null);
  }, [userRV]);

  return {
    items,
    setItems,
    totalCart,
    openModal,
    setOpenModal,
  };
};

export default useModalCart;
