import React from 'react';

import Image from './Image';

interface ShowConditionsButtonProps {
  onClick: () => void;
  freeDeliveryImage: 'freedeliverysmall' | 'freedeliveryheader';
  height: string;
  width: string;
  className: string;
  noImage?: boolean;
}

const ShowConditionsButton = ({
  onClick,
  freeDeliveryImage,
  width,
  height,
  className,
  noImage,
}: ShowConditionsButtonProps): JSX.Element => {
  return (
    <div className={className}>
      {noImage ? null : (
        <Image
          alt="Gastos de Envío Gratis"
          src={`img/freedelivery/${freeDeliveryImage}.svg`}
          addCdnPrefix
          width={width}
          height={height}
        />
      )}

      <button
        aria-label="Condiciones-info"
        type="button"
        className="conditionsButton"
        onClick={(): void => onClick()}
      >
        <p>Ver Condiciones</p>
      </button>
    </div>
  );
};

export default ShowConditionsButton;
