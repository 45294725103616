import create from 'zustand';
import { HandleModalCartType } from '../../layouts/Header/ModalCart/modalCart.model';
import { Basket } from '../../graphqlTypes';

interface StoreModalCart {
  items: Basket[];
  totalCart: number;
  openModal: HandleModalCartType;
  setItems: (item: Basket[]) => void;
  setTotalItems: (total: number) => void;
  setOpenModal: (openModal: HandleModalCartType) => void;
}

const useStoreModalCart = create<StoreModalCart>((set) => ({
  items: null,
  totalCart: null,
  openModal: HandleModalCartType.Initial,
  setItems: (items: Basket[]): void => {
    set((prevState) => ({ ...prevState, items }));
  },
  setTotalItems: (totalCart: number): void => {
    set((prevState) => ({ ...prevState, totalCart }));
  },
  setOpenModal: (openModal: HandleModalCartType): void => {
    set((prevState) => ({ ...prevState, openModal }));
  },
}));

export default useStoreModalCart;
