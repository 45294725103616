import React, { ReactNode } from 'react';

interface HolderProps {
  className?: string;
  end?: boolean;
  children: ReactNode;
  start?: boolean;
  between?: boolean;
  around?: boolean;
  center?: boolean;
  column?: boolean;
}

const Holder = ({
  className: classes,
  end,
  children,
  start,
  between,
  around,
  center,
  column,
}: HolderProps): JSX.Element => {
  const className = `holder ${classes || ''}${end ? ' end' : ''}${start ? ' start' : ''}${
    between ? ' between' : ''
  }${around ? ' around' : ''}${center ? ' center' : ''}${column ? ' FLEX-COL' : ''}`;

  return <div className={className}>{children}</div>;
};

export default Holder;
