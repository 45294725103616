import React from 'react';

import Modal from '../components/alerts/Modal';
import PopUp from '../components/alerts/PopUp';
import Holder from '../layouts/Holder';
import PageContent from './PageContent';

interface ShippingConditionsProps {
  show?: boolean;
  setShowModal?: () => void;
}

const ShippingConditions = ({ show, setShowModal }: ShippingConditionsProps): JSX.Element => {
  if (!show) return null;

  return (
    <PopUp>
      <Modal
        one
        options={{
          confirm: true,
          confirmFn: setShowModal,
          confirmText: 'Aceptar',
        }}
      >
        <PageContent id="disclaimer" className="shippingConditions">
          <div className="row">
            <h2>Gastos de envío Gratis</h2>

            <Holder center column>
              <div className="text noSidePad">
                <p>
                  Los envíos con destino España y Portugal peninsulares son gratuitos en los pedidos
                  superiores a 35 €. Los pedidos de menos de 35 € tendrán un coste de 3 €. Los
                  envíos a las Islas Canarias, Baleares, Ceuta y Melilla, serán gratuitos utilizando
                  el método de envió CORREOS siempre que el pedido sea superior a 100 €, en caso de
                  ser menor, tendrá un coste en proporción al valor del pedido, que se mostrará en
                  el apartado de gastos de envío del carro de la compra. Para el resto de países,
                  las tarifas se mostrarán en el carro de compra en función del peso de los
                  artículos y el país de destino.
                </p>
              </div>
            </Holder>
          </div>
        </PageContent>
      </Modal>
    </PopUp>
  );
};

export default ShippingConditions;
