import * as Sentry from '@sentry/nextjs';
import { createCompraConversion } from '../../../utils/analytics';
import { getOrderService } from './order.service';
import {
  AmountDelivery,
  Book,
  CheckStockItemQuery,
  CheckStockItemQueryVariables,
  Coupon,
  CouponCheckQuery,
  CouponCheckQueryVariables,
  CreatePaymentMutation,
  CreatePaymentMutationVariables,
  EmptyBasketDocument,
  EmptyBasketMutation,
  EmptyBasketMutationVariables,
  ExecutePaymentMutation,
  ExecutePaymentMutationVariables,
  GetRateRequestQuery,
  GetRateRequestQueryVariables,
  GetUserBasketQuery,
  GetUserBasketQueryVariables,
  Payment,
  ShippingApiData,
  ShippingApiQuery,
  ShippingApiQueryVariables,
  User,
} from '../../../graphqlTypes';
import {
  checkStockItem,
  couponCheck,
  getBasketUser,
  getRateRequest,
  shippingAPI,
} from '../../../graphql/queries';
import { initializeApollo } from '../../../graphql/apollo';
import { Response } from '../../../models/response.model';
import { newPayment, executePayment } from '../../../graphql/mutations';

/**
 * Function calculate the price for delivery in DHL by Address
 * @param variables
 * @returns
 */
export const getRateRequestByAddress = async (
  variables: GetRateRequestQueryVariables,
): Promise<Response<AmountDelivery>> => {
  try {
    const { query } = initializeApollo();
    const { data, error } = await query<GetRateRequestQuery>({
      query: getRateRequest,
      variables,
      fetchPolicy: 'network-only',
    });
    const amountDelivery = data?.getRateRequest as AmountDelivery;

    return { ok: !!amountDelivery, data: amountDelivery, messageError: error?.message };
  } catch (e: any) {
    return { ok: false, messageError: e?.message };
  }
};

/**
 * Function calculate the price delivery for other companies like NACEX or correos
 * @param variables
 * @returns
 */
export const getPriceShipping = async (
  variables: ShippingApiQueryVariables,
): Promise<Response<ShippingApiData[]>> => {
  try {
    const { query } = initializeApollo();
    const { data, error } = await query<ShippingApiQuery>({
      query: shippingAPI,
      variables,
      fetchPolicy: 'network-only',
    });
    const shippingData: ShippingApiData[] = data?.shippingAPI;

    return { ok: !!shippingData, data: shippingData, messageError: error?.message };
  } catch (e: any) {
    return { ok: false, messageError: e?.message };
  }
};

/**
 * Function check if the current product has stock
 * @param variables
 * @returns
 */
export const checkStockProduct = async (
  variables: CheckStockItemQueryVariables,
): Promise<Response<Book[]>> => {
  try {
    const { query } = initializeApollo();
    const { data, error } = await query<CheckStockItemQuery>({
      query: checkStockItem,
      variables,
      fetchPolicy: 'network-only',
    });

    const productData = data?.checkStockItem as Book[];

    return { ok: !!productData, data: productData, messageError: error?.message };
  } catch (e: any) {
    return { ok: false, messageError: e?.message };
  }
};

/**
 * Function ends a Payment in server
 * @param variables
 * @returns
 */
export const createNewPayment = async (
  variables: CreatePaymentMutationVariables,
  token?: string,
): Promise<Response<Payment>> => {
  try {
    const { mutate } = initializeApollo({}, token);
    const { data } = await mutate<CreatePaymentMutation>({
      mutation: newPayment,
      variables,
      fetchPolicy: 'network-only',
    });
    const { createPayment } = data;

    if (createPayment?.errorCode) {
      return { ok: false, messageError: createPayment.errorCode };
    }

    const paymentData: Payment = data?.createPayment;

    return { ok: !!paymentData, data: paymentData };
  } catch (e: any) {
    Sentry.captureException(e, {
      tags: { payments: true },
    });
    return { ok: false, messageError: e?.message };
  }
};

export const getBasketQueryService = async (
  variables: GetUserBasketQueryVariables,
): Promise<Response<User>> => {
  try {
    const { query } = initializeApollo();
    const { data } = await query<GetUserBasketQuery>({
      query: getBasketUser,
      variables,
      fetchPolicy: 'cache-first',
    });
    const currentUser: User = data?.user;

    return {
      ok: !!currentUser,
      data: currentUser,
    };
  } catch (error: any) {
    return {
      ok: false,
      messageError: error?.message,
    };
  }
};

export const emptyCartUser = async (
  variables: EmptyBasketMutationVariables,
): Promise<Response<User>> => {
  try {
    const { mutate } = initializeApollo();
    const { data } = await mutate<EmptyBasketMutation>({
      mutation: EmptyBasketDocument,
      variables,
    });
    const emptyBasket = data?.emptyBasket;

    return {
      ok: !!emptyBasket,
      data: emptyBasket,
    };
  } catch (error: any) {
    return {
      ok: false,
      messageError: error?.message,
    };
  }
};

/**
 * Function validate if coupon is valid or exist
 * @param variables
 * @returns
 */
export const checkCouponService = async (
  variables: CouponCheckQueryVariables,
): Promise<Response<Coupon>> => {
  try {
    const { query } = initializeApollo();
    const { data, error } = await query<CouponCheckQuery>({
      query: couponCheck,
      variables,
      fetchPolicy: 'network-only',
    });

    if (error) {
      return {
        ok: false,
        messageError: error.message,
      };
    }

    const coupon = data?.couponCheck;

    return {
      ok: !!coupon,
      data: coupon,
    };
  } catch (error: any) {
    return {
      ok: false,
      messageError: error?.message,
    };
  }
};

export const confirmPaypalPayment = async (
  variables: ExecutePaymentMutationVariables,
  token: string,
): Promise<Response<Payment>> => {
  try {
    const { mutate } = initializeApollo({}, token);
    const { data } = await mutate<ExecutePaymentMutation>({ mutation: executePayment, variables });
    const payment = data?.executePayment;
    const { data: order } = await getOrderService(
      { _id: null, order: variables?.paymentId },
      token,
    );
    createCompraConversion(order);

    return {
      ok: !!payment,
      data: payment,
    };
  } catch (error: any) {
    Sentry.captureException(error, {
      tags: { paypal: true },
    });
    return {
      ok: false,
      messageError: error?.message,
    };
  }
};
